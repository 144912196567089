<template>
  <div class="iframeModule">
<!--    <iframe id="iframe" name="ifrmname" frameborder="0" :src="srcs"></iframe>-->
    <WujieVue width="100%" height="100%" name="module_bidding" :url="moduleUrl" :props="{method:{propsMethod}}" :sync="true" />
  </div>
</template>

<script>
import hostMap from "@/hostMap";
import {mapState} from "vuex";
import store from "@/store/index";


export default {
  data() {
    return {
      userMes: null,
      srcs: "",
      newStr: new Date().getTime(),
      moduleUrl: null,
      urlQuery: ''
    };
  },
  created() {
    let that = this;
    this.userMes = this.$store.state.common.sessionInfo;
    that.srcs =
      JSON.parse(localStorage.getItem("setHeaderText")).url +
      `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
        that.userMes.sessionStr
      }&tel=${that.userMes.tel}&userId=${
        that.userMes.userId
      }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1&date=${
        this.newStr
      }`;
    if (that.srcs.indexOf("undefined") != -1) {
      that.srcs = localStorage.getItem("iframeUrl");
    } else {
      localStorage.setItem("iframeUrl", that.srcs);
    }
  },
  mounted() {
   this.urlQuery = this.$route.query
   this.moduleUrl =  hostMap("//localhost:3001/") + '#'+ JSON.parse(localStorage.getItem("setHeaderText")).url
  },
  computed: {
    ...mapState({
      src: state => state.search.headerText.url
    }),
  },
  watch: {
    src(val) {
      this.moduleUrl =  hostMap("//localhost:3001/") + '#'+ JSON.parse(localStorage.getItem("setHeaderText")).url
    }
  },
  methods: {
    propsMethod() {
      store.commit("setSessionInfo", "");
      store.commit("setSelectFirmApplyCommodity", "");
      sessionStorage.clear();
      global.router.push("/login");
    }
  }
};
</script>

<style lang="scss" scoped>
.iframeModule {
  height: 100%;
  width: 100%;
}
</style>
